import React, { useRef, useEffect } from "react"
import CloudPdfViewer from "@openbook/cloudpdf-viewer"
const Isms = () => {
  const viewer = useRef(null)
  useEffect(() => {
    CloudPdfViewer(
      {
        documentId: "0380a795-5bcd-40ca-af48-5cc557fa4d17",
        darkMode: true,
      },
      viewer.current
    ).then(instance => {})
  }, [])
  return (
    <div style={{ margin: "12rem 0 2rem" }}>
      <div style={{ height: "100vh" }} ref={viewer}></div>
    </div>
  )
}

export default Isms
