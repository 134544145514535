import React from "react"
import Isms from "../components/Isms"
import Layout from "../components/Layout"

const document = () => {
  return (
    <Layout
      pageMeta={{
        title: "Altamiuz ISMS",
        description: "بيان نظام إدارة أمن المعلومات",
        keywords: [
          "أمن المعلومات",
          "شهادات أمنية معتمدة",
          "آيزو",
          "iso",
          "ليبيا",
          "libya",
          "التميز",
          "altamiuz",
        ],
      }}
    >
      <Isms />
    </Layout>
  )
}

export default document
